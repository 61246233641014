import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import EmbedYoutube from "../../components/EmbedYoutube";
import "twin.macro";
import { StaticImage } from "gatsby-plugin-image";

export default function cooking() {
  return (
    <Layout>
      <SEO title="Online Marketing" />
      <div tw="flex flex-col justify-center text-center relative">
        <h1
          data-fontsize="54"
          data-lineheight="62.64px"
          tw="text-primary text-4xl md:text-6xl my-8 font-black"
        >
          ONLINE MARKETING
        </h1>

        <div
          className=""
          tw="font-bold text-xl md:text-2xl px-4 mb-4 md:mb-24 "
        >
          <h3 data-fontsize="22" data-lineheight="28.6px" tw="mb-4 ">
            Affiliate Marketing • Suchmaschinenmarketing • Online-Werbung
          </h3>
          <h3 tw="">Social Media • Online PR</h3>
        </div>
        <div className="" tw="flex justify-center mb-4 md:mb-24">
          <EmbedYoutube videoId="h0fQsTf46Bw" />
        </div>
        {/* picture */}
        <div className="imageframe-align-center" tw="mx-4 md:mx-8">
          <span
            style={{ width: "100%", maxWidth: "2000px" }}
            className="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
          >
            <StaticImage
              src="../../images/Online-1-scaled.jpg"
              width="2246"
              height="2560"
              alt=""
              title="Cooking school"
            />
          </span>
        </div>
      </div>
    </Layout>
  );
}
